<template>
  <div class="my-bav my-bav__table">
    <input id="hiddenInput" ref="hiddenInput" type="file" class="hidden-input" @change="onFileListChange" />
    <v-card class="my-bav__table-card" :class="{ 'v-card--no-border': $vuetify.breakpoint.smAndDown }">
      <v-card-text
        class="border-top border-left border-right"
        :class="[{ 'border--dark': !$vuetify.breakpoint.smAndDown }]"
      >
        <h2 class="text--primary my-bav__table-heading">
          {{ $t('myBavSearchNFilter') }}
        </h2>
      </v-card-text>
      <v-card-text
        class="d-flex align-center flex-wrap pb-0 border-left border-right"
        :class="[
          { 'px-2': $vuetify.breakpoint.smAndDown },
          { 'mb-2': $vuetify.breakpoint.smAndDown },
          { 'border-bottom': $vuetify.breakpoint.smAndDown },
          { 'border--dark': !$vuetify.breakpoint.smAndDown },
        ]"
      >
        <v-row class="px-3">
          <v-col cols="12" md="5" sm="12" :class="{ 'px-0': !$vuetify.breakpoint.smAndDown }">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              clearable
              :append-icon="icons.mdiMagnify"
              :label="$t('labelSearchEmployee')"
              :placeholder="$t('labelSearchEmployee')"
              :class="{
                'mb-5 my-bav__table-filter': !$vuetify.breakpoint.smAndDown,
              }"
              @input="searchData"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="1"></v-col>
          <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
            <v-select
              v-model="filterByCompany"
              :items="companiesOptions"
              single-line
              outlined
              dense
              hide-details
              clearable
              :label="$t('adminCompanyFilterLabel')"
              :placeholder="$t('adminCompanyFilterLabel')"
              :class="{
                'mb-5 pr-0 my-bav__table-filter': !$vuetify.breakpoint.smAndDown,
              }"
              @change="setFilterByCompany"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text
        class="d-flex justify-space-between flex-wrap pb-0"
        :class="[
          { 'mb-4 border': $vuetify.breakpoint.smAndDown },
          { 'border--dark align-items-center': !$vuetify.breakpoint.smAndDown },
          { 'border-right': !$vuetify.breakpoint.smAndDown },
          { 'border-left': !$vuetify.breakpoint.smAndDown },
          { 'border-top': !$vuetify.breakpoint.smAndDown },
        ]"
      >
        <span class="py-6" :class="[{ 'py-4': $vuetify.breakpoint.smAndDown }]">
          <a
            color="primary"
            class="text-decoration-underline mr-3 px-0 v-card--no-bg d-flex align-items-center"
            :class="{
              'text--secondary v-btn--disabled': selectedEmployees.length === 0,
            }"
            :disabled="selectedEmployees.length === 0"
            @click.prevent="onDelete(false)"
          >
            <v-icon
              size="22"
              class="mr-1"
              :color="selectedEmployees.length !== 0 ? 'primary' : ''"
              :class="[
                {
                  'text--secondary v-btn--disabled': selectedEmployees.length === 0,
                },
              ]"
            >
              {{ icons.mdiDelete }}
            </v-icon>
            <span>
              {{ $t('myBavDeleteSelected') }}
            </span>
          </a>
        </span>
        <v-spacer></v-spacer>
        <div v-if="activeRole !== 'ROLE_KEY_ACCOUNT'">
          <span class="py-4">
            <v-btn color="primary" class="mr-3 add-employees-btn" @click="uploadEmployeeListModal = true">
              {{ $t('adminEmployeeUpload') }}
            </v-btn>
          </span>
          <span class="py-5">
            <v-btn color="primary" class="add-employees-btn" @click="addEmployeeModal = true">
              {{ $t('adminEmployeeAdd') }}
            </v-btn>
          </span>
        </div>
      </v-card-text>

      <v-data-table
        :key="rerenderKey"
        v-model="selectedEmployees"
        :items="loadedEmployees"
        :headers="headers"
        :server-items-length="totalLoadedEmployees"
        :mobile-breakpoint="0"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [15, 30, 50, 100],
          'items-per-page-text': $t('adminEmployeeTablePerPage'),
        }"
        :sort-by.sync="sortingOptions"
        :sort-desc.sync="sortingIsDesc"
        show-select
        class="text-no-wrap cursor-pointer"
        @update:items-per-page="setLimit"
        @pagination="handlePagination"
        @click:row="(row) => $router.push('/profile/' + row.id)"
      >
        <template #[`header.trending`]>
          <v-icon size="22">
            {{ icons.mdiTrendingUp }}
          </v-icon>
        </template>

        <template #[`item.personalDetails.salutation`]="{ item }">
          <span class="text-no-wrap th">
            {{ $t(SALUTATION_TRANSLATION_MAP[item.personalDetails.salutation]) }}
          </span>
        </template>

        <template #[`item.personalDetails.firstName`]="{ item }">
          <span>{{ item.personalDetails.firstName || '—' }}</span>
        </template>

        <template #[`item.item.personalDetails.lastName`]="{ item }">
          <span>{{ item.personalDetails.lastName || '—' }}</span>
        </template>

        <template #[`item.company.companyName`]="{ item }">
          <span>{{ item.company.companyName }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on">
                  <v-icon size="24" color="primary">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-list-item class="border-bottom cursor-pointer px-0">
                      <v-btn text plain small v-bind="attrs" v-on="on" @click="triggerFileUpload(item.id)">
                        <v-icon size="16" class="mr-1">
                          {{ icons.mdiUploadOutline }}
                        </v-icon>
                        <span>{{ $t('uploadDocument') }}</span>
                      </v-btn>
                    </v-list-item>
                  </template>
                  <span>{{ $t('uploadDocument') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-list-item class="border-bottom cursor-pointer px-0">
                      <v-btn text plain small v-bind="attrs" v-on="on" @click="$router.push('/profile/' + item.id)">
                        <v-icon size="16" class="mr-1">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span>{{ $t('edit') }}</span>
                      </v-btn>
                    </v-list-item>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-list-item class="cursor-pointer px-0">
                      <v-btn text plain small v-bind="attrs" v-on="on" @click="onDelete(item.id)">
                        <v-icon size="16" class="mr-1">
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span>{{ $t('delete') }}</span>
                      </v-btn>
                    </v-list-item>
                  </template>
                  <span>{{ $t('delete') }}</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template slot="no-data">
          <div class="my-5">
            <p>{{ $t('myBavNoResults') }}</p>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <ModalWindow :is-open="uploadDocumentsModal" :title="$t('myBavUploadModalTitle')" :stacked="true">
      <template #content>
        <v-card v-for="(file, index) in documentsToUpload" :key="file.size + '' + index" class="mb-5 border-bottom">
          <v-card-text class="py-3 pb-0 text-left">
            <p>{{ file.source.name }}</p>
            <v-select
              v-model="file.category"
              :items="categoryOptions"
              :label="$t('myBavUploadModalCategoryLabel')"
              :placeholder="$t('myBavUploadModalCategoryLabel')"
              dense
              outlined
            ></v-select>
          </v-card-text>
        </v-card>
      </template>
      <template #actions>
        <v-btn type="submit" block color="primary" class="btn--default mt-6" @click="confirmUpload">
          {{ $t('buttonConfirm') }}
        </v-btn>
        <v-btn
          outlined
          block
          color="primary"
          class="btn--default mt-3 mr-3"
          @click="
            uploadDocumentsModal = false;
            documentsToUpload = [];
          "
        >
          {{ $t('buttonCancel') }}
        </v-btn>
      </template>
    </ModalWindow>

    <!-- delete  -->

    <ModalWindow :is-open="deleteEmployeeModal" error>
      <template #content>
        <p class="text-base">
          {{
            $t('deleteEmployeeConfirmation', {
              count: selectedEmployees.length || '1',
            })
          }}
        </p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="deleteEmployeeModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirmDelete">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
    <ModalWindow :is-open="uploadEmployeeListModal" :hasForm="true" :maxWidth="550">
      <template #content>
        <v-row class="text-center mb-0 pb-0">
          <v-col class="pb-0">
            <p class="text-base font-weight-bold pt-3" style="color: #505a68">
              {{ $t('adminEmployeeUpload') }}
            </p>
          </v-col>
        </v-row>

        <v-form id="bulkUpload" ref="bulkUploadForm" v-model="isFormValid" @submit.prevent="onSubmit">
          <v-row align="center" justify="center">
            <v-col cols="7" md="8" class="pb-0 px-0 px-lg-1">
              <v-file-input
                v-model="bulkUploadEmployeeFile"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                :prepend-inner-icon="icons.mdiPaperclip"
                prepend-icon=""
                :label="$t('buttonSelectFile')"
                :rules="[validators.fileRequired]"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #actions>
        <v-row class="d-flex align-center justify-center pb-3">
          <v-col cols="9" md="6" class="pt-0 order-last order-md-first">
            <v-btn
              block
              outlined
              color="primary"
              @click="
                uploadEmployeeListModal = false;
                bulkUploadEmployeeFile = null;
              "
            >
              {{ $t('buttonCancel') }}
            </v-btn>
          </v-col>
          <v-col cols="9" md="6" class="pt-0">
            <v-btn block color="primary" type="submit" form="bulkUpload">
              {{ $t('buttonUpload') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ModalWindow>
    <ModalWindow :is-open="addEmployeeModal" :maxWidth="700" :hasForm="true" :centered="true">
      <template #content>
        <v-row justify="center">
          <v-card-text class="px-0">
            <p class="text-base text-center font-weight-bold pt-md-4 pt-2">
              {{ $t('adminAddEmployeeHeading') }}
            </p>
            <p class="text-center mb-0">{{ $t('adminAddEmployeeLead') }}</p>
          </v-card-text>
        </v-row>
        <v-row>
          <v-card-text class="text-base pa-3">{{ $t('formLabelSalutation') }} </v-card-text>
        </v-row>
        <add-employee-form
          :personalData="personalData"
          :companiesOptions="companiesOptions"
          formId="addEmployeeForm"
          class="my-bav__table"
          @created="onCreatedEmployee"
        />

        <v-row class="mt-6">
          <v-col cols="12" md="6" class="order-last order-md-first pb-0">
            <v-btn block outlined color="primary" class="btn--default" @click="addEmployeeModal = false">
              {{ $t('buttonCancel') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-btn block color="primary" class="btn--default" type="submit" form="addEmployeeForm">
              {{ $t('buttonAddEmployee') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiDownloadOutline,
  mdiInformationOutline,
  mdiCheckCircle,
  mdiUploadOutline,
  mdiMagnify,
  mdiPaperclip,
  mdiDelete,
} from '@mdi/js';

import { required, fileRequired } from '@core/utils/validation';

import moment from 'moment';
import { debounce } from 'lodash';

import { ref, getCurrentInstance, onMounted, computed, watch } from '@vue/composition-api';
import {
  ROLE_BAV_MANAGER,
  ROLE_KEY_ACCOUNT,
  SALUTATION_TRANSLATION_MAP,
  CONTRACT_TRANSLATION_MAP,
  CONTRACT_EXISTING,
  CONTRACT_ONBOARDED,
  CONTRACT_OFFER_REQUESTED,
  CONTRACT_OFFER_ACCEPTED,
  CONTRACT_APPLICATION,
  CONTRACT_SIGNED,
  CONTRACT_OPTED_OUT,
  CONTRACT_SUSPENDED,
  SALUTATION_MR,
  DOC_OTHER,
  CATEGORY_TRANSLATION_MAP,
  EMPLOYEE_CATEGORIES,
} from '@/constants';

import { uploadDocument } from '@/api/document';
import { getCompanyById, getCompaniesList } from '@/api/companies';
import { deleteEmployee } from '@/api/employee';
import DragNDropUpload from '@/components/upload/DragNDropUpload';
import ModalWindow from '@/components/modal/ModalWindow';
import AddEmployeeForm from '@/components/employee-add/AddEmployeeForm.vue';

export default {
  name: 'EmployeesTable',
  components: { DragNDropUpload, ModalWindow, AddEmployeeForm },
  setup() {
    const vm = getCurrentInstance().proxy;
    const currentPage = ref(1);
    const rerenderKey = ref(0);

    const bulkUploadForm = ref(null);
    const isFormValid = ref(false);

    const companiesOptions = ref([]);
    const documentsToUpload = ref([]);
    const sortingOptions = ref(['personalDetails.firstName']);
    const statusFilter = ref('');
    const searchQuery = ref('');
    const filterByCompany = ref('');

    const selectedEmployees = ref([]);

    const sortingIsDesc = ref(false);
    const addEmployeeModal = ref(false);
    const uploadEmployeeListModal = ref(false);
    const deleteEmployeeModal = ref(false);
    const uploadDocumentsModal = ref(false);
    const grantHrAccessWithUpload = ref(true);
    const employeeToDelete = ref(null);
    const employeeToUploadDoc = ref(null);
    const bulkUploadEmployeeFile = ref(null);
    const hiddenInput = ref(null);

    const loadedEmployees = computed(() => vm.$store.getters['employees/EMPLOYEES_PAGE']);
    const totalLoadedEmployees = computed(() => vm.$store.state.employees.total);
    const loading = computed(() => vm.$store.state.employees.loading);
    const { activeRole } = vm.$store.getters;
    const { userData } = vm.$store.getters;

    const categoryOptions = computed(() => {
      return EMPLOYEE_CATEGORIES.map((e) => ({
        text: vm.$t(CATEGORY_TRANSLATION_MAP[e]),
        value: e,
      }));
    });

    const statusOptions = ref([
      {
        value: CONTRACT_ONBOARDED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_ONBOARDED]),
      },
      {
        value: CONTRACT_OFFER_REQUESTED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_REQUESTED]),
      },
      {
        value: CONTRACT_OFFER_ACCEPTED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_ACCEPTED]),
      },
      {
        value: CONTRACT_APPLICATION,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_APPLICATION]),
      },
      {
        value: CONTRACT_SIGNED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SIGNED]),
      },
      {
        value: CONTRACT_EXISTING,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_EXISTING]),
      },
      {
        value: CONTRACT_OPTED_OUT,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OPTED_OUT]),
      },
      {
        value: CONTRACT_SUSPENDED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SUSPENDED]),
      },
    ]);

    const headers = computed(() => {
      return [
        {
          text: vm.$t('tableHeaderSalutation'),
          class: 'text-uppercase',
          value: 'personalDetails.salutation',
          sortable: false,
        },
        {
          text: vm.$t('tableHeaderFirstName'),
          class: 'text-uppercase',
          value: 'personalDetails.firstName',
          sortable: true,
        },
        {
          text: vm.$t('tableHeaderLastName'),
          class: 'text-uppercase',
          value: 'personalDetails.lastName',
          sortable: true,
        },
        {
          text: vm.$t('tableHeaderCompany'),
          class: 'text-uppercase',
          value: 'company.companyName',
          sortable: false,
        },
        {
          text: vm.$t('tableHeaderActions'),
          class: 'text-uppercase',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ];
    });

    const fetchEmployees = () => {
      vm.$store.dispatch('employees/fetchAll');
    };

    const fetchCompanies = async () => {
      const companies = [];

      if (activeRole === ROLE_BAV_MANAGER) {
        const promises = userData.data.companyIdsAssigned.map(
          (id) =>
            new Promise((resolve) => {
              getCompanyById(id).then((res) => {
                companies.push({
                  text: res.data.companyName,
                  value: res.data.id,
                });
                resolve();
              });
            }),
        );

        await promises;
        companiesOptions.value = companies;
      }

      if (activeRole === ROLE_KEY_ACCOUNT) {
        getCompaniesList(0, 0).then((res) => {
          companiesOptions.value = res.data.page.map((i) => ({
            text: i.companyName,
            value: i.id,
          }));
        });
      }
    };

    const onFileListChange = () => {
      createUploadObjects(hiddenInput.value.files);
    };

    const triggerFileUpload = (id) => {
      hiddenInput.value.click();
      employeeToUploadDoc.value = id;
    };

    const resetFileUpload = () => {
      uploadDocumentsModal.value = false;
      employeeToUploadDoc.value = null;
      hiddenInput.value.reset();
    };

    const createUploadObjects = (files) => {
      uploadDocumentsModal.value = true;
      documentsToUpload.value = [];
      grantHrAccessWithUpload.value = false;

      for (let i = 0; i < files.length; i++) {
        documentsToUpload.value[i] = {
          fileIndex: i,
          cancelled: false,
          category: DOC_OTHER,
          categorized: false,
          id: null,
          loaded: 0,
          failureReason: null,
          source: files[i],
        };
      }
    };

    const confirmUpload = () => {
      documentsToUpload.value.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file.source);
        formData.append(
          'document',
          JSON.stringify({
            employeeId: employeeToUploadDoc.value,
            name: file.source.name,
            category: file.category,
            scope: 'EMPLOYEE',
            salesforceOnly: false,
            employeeOnly: false,
          }),
        );

        await uploadDocument(formData, activeRole);
        vm.$store.commit('showMessage', {
          text: 'successChangeFieldHeadline',
          color: 'success',
          timeout: '10000',
        });
      });

      resetFileUpload();
    };

    const onSubmit = () => {
      const isValid = bulkUploadForm.value.validate();
      if (!isValid) return;

      bulkUpload();
    };

    const searchData = debounce(function () {
      vm.$store.dispatch('employees/search', { search: searchQuery.value });
    }, 300);

    const setStatus = () => {
      vm.$store.dispatch('employees/filterStatus', statusFilter.value);
    };

    const setFilterByCompany = () => {
      vm.$store.dispatch('employees/filterCompany', filterByCompany.value);
    };

    const setSorting = (fieldName, direction) => {
      vm.$store.commit('employees/SET_SORT', direction);
      vm.$store.dispatch('employees/sort', fieldName);
    };

    const setLimit = (limit) => {
      vm.$store.dispatch('employees/setLimit', limit);
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) onNextPage();
      if (currentPage.value > event.page) onPreviousPage();

      currentPage.value = event.page;
    };

    const onCreatedEmployee = () => {
      addEmployeeModal.value = false;
      fetchEmployees();
      vm.$store.commit('showMessage', {
        text: 'messageEmployeeImported',
        color: 'success',
        timeout: '10000',
      });
    };

    const onPreviousPage = () => {
      vm.$store.dispatch('employees/previousPage');
    };

    const onNextPage = () => {
      vm.$store.dispatch('employees/nextPage');
    };

    const onDelete = (id) => {
      deleteEmployeeModal.value = true;
      if (id) employeeToDelete.value = id;
    };

    const confirmDelete = () => {
      if (selectedEmployees.value.length > 0) {
        return confirmMultipleDelete();
      }
      return deleteEmployee(employeeToDelete.value).then(() => {
        deleteEmployeeModal.value = false;
        fetchEmployees();
      });
    };

    const confirmMultipleDelete = () => {
      const promiseArr = [];

      selectedEmployees.value.map(async (i) => {
        promiseArr.push(deleteEmployee(i.id));
      });

      Promise.all(promiseArr).then(() => {
        selectedEmployees.value = [];
        deleteEmployeeModal.value = false;
        fetchEmployees();
      });
    };

    onMounted(() => {
      vm.$store.commit('employees/__RESET');
      fetchCompanies();
      setLimit(15);
    });

    watch(sortingIsDesc, () => {
      if (sortingOptions.value.length > 0) {
        const direction = sortingIsDesc.value ? 'DESC' : 'ASC';
        setSorting(sortingOptions.value[0], direction);
      }
    });

    watch(
      () => vm.$store.state.app.currentLang,
      () => {
        statusOptions.value = [
          {
            value: CONTRACT_ONBOARDED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_ONBOARDED]),
          },
          {
            value: CONTRACT_OFFER_REQUESTED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_REQUESTED]),
          },
          {
            value: CONTRACT_OFFER_ACCEPTED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OFFER_ACCEPTED]),
          },
          {
            value: CONTRACT_APPLICATION,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_APPLICATION]),
          },
          {
            value: CONTRACT_SIGNED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SIGNED]),
          },
          {
            value: CONTRACT_EXISTING,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_EXISTING]),
          },
          {
            value: CONTRACT_OPTED_OUT,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_OPTED_OUT]),
          },
          {
            value: CONTRACT_SUSPENDED,
            text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_SUSPENDED]),
          },
        ];
        rerenderKey.value += 1;
        vm.$forceUpdate();
      },
    );

    return {
      hiddenInput,
      rerenderKey,
      currentPage,
      headers,
      loadedEmployees,
      searchQuery,
      loading,
      totalLoadedEmployees,
      statusFilter,
      moment,
      statusOptions,
      companiesOptions,
      searchData,
      sortingOptions,
      sortingIsDesc,
      addEmployeeModal,
      uploadEmployeeListModal,
      filterByCompany,
      deleteEmployeeModal,
      uploadDocumentsModal,
      employeeToDelete,
      employeeToUploadDoc,
      documentsToUpload,
      grantHrAccessWithUpload,
      categoryOptions,
      bulkUploadEmployeeFile,
      setFilterByCompany,
      setStatus,
      setLimit,
      handlePagination,
      onFileListChange,
      onDelete,
      onSubmit,
      isFormValid,
      bulkUploadForm,
      confirmUpload,
      confirmDelete,
      onCreatedEmployee,
      triggerFileUpload,
      bulkUpload,
      activeRole,
      selectedEmployees,
      ROLE_BAV_MANAGER,
      ROLE_KEY_ACCOUNT,
      SALUTATION_TRANSLATION_MAP,
      CONTRACT_TRANSLATION_MAP,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownloadOutline,
        mdiPencilOutline,
        mdiInformationOutline,
        mdiCheckCircle,
        mdiUploadOutline,
        mdiMagnify,
        mdiPaperclip,
        mdiDelete,
      },
      personalData: {
        salutation: SALUTATION_MR,
        firstName: '',
        lastName: '',
        city: '',
        postalCode: '',
        streetAddress: '',
        country: '',
        cityOfBirth: '',
        countryOfBirth: '',
        dateOfBirth: '',
        emailPersonal: '',
        emailBusiness: '',
        phoneNumber: '',
        contract: false,
        language: 'de',
      },
      validators: {
        required,
        fileRequired,
      },
    };
  },
};
</script>

<style lang="scss">
.add-employees-btn {
  background: #2830ce;
  border-radius: 3px;
  width: 320px;
}

.hidden-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.min-width-text {
  min-width: 160px;
}
.min-width-card {
  min-width: 500px;
}
.th {
  max-width: 280px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: block;
}
.hidden-input-container:hover {
  background-color: rgba(138, 141, 147, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
}
.hidden-input-container {
  background: #ffffff;
  border: 1px solid #2830ce;
  border-radius: 3px;
  height: 139px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.my-bav {
  &__table {
    .v-select__selection {
      color: #2830ce !important;
    }

    .v-data-table__checkbox,
    .v-input--checkbox {
      .v-icon__svg,
      .v-icon {
        color: #2830ce !important;
      }
    }

    .v-data-footer {
      color: #2830ce !important;
      border-color: rgba(40, 48, 206, 0.25) !important;
      button.v-btn--disabled {
        opacity: 0.4;
      }
      svg {
        fill: #2830ce;
      }
    }
    th {
      background: rgba(40, 48, 206, 0.25) !important;
      border-top: 1px solid rgba(40, 48, 206, 0.15) !important;
      border-color: #2830ce !important;
      border-bottom: none !important;
      span {
        color: #2830ce !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
      }
    }
    tr,
    td {
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-card {
      border-radius: 3px !important;
      /*margin-top: 31px;*/
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #505a68;
    }
    &-filter {
      /*max-width: 320px;*/
      min-width: 320px;
    }
    &-info {
      padding: 10px 8px;
      border: 1px solid #2830ce;
      border-radius: 3px;
      margin: 8px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      background-color: white;
      /*transform: translateX(50%) translateY(10px);*/
      color: #505a68;

      ul {
        padding: 0;
        padding-left: 12px;
      }
      &-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #2830ce;
        margin-bottom: 3px;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .add-employees-btn {
    margin: 8px 0 !important;
    width: 100% !important;
  }
  .my-bav {
    &__table {
      table {
        border: 1px solid rgba(40, 48, 206, 0.15) !important;
      }
    }
    .v-data-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .v-data-footer__icons-after,
    .v-data-footer__icons-before {
      max-width: 16px;
      max-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-data-footer__select {
      min-width: unset !important;
    }
    .v-data-footer__pagination {
      margin: 0 10px;
    }
  }
}
</style>

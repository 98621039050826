<template>
  <div class="py-sm-10 py5">
    <div class="row">
      <div class="mx-auto col-sm-12 col-md-10 col-lg-10 col-12">
        <v-card class="v-card--no-border" color="transparent">
          <h1 class="font-weight-bold mb-5 text-center">
            {{ $t('adminEmployeeHeadingOverview') }}
          </h1>
          <p
            v-if="activeRole !== 'ROLE_KEY_ACCOUNT'"
            class="text-center mx-auto"
            v-html="$t('adminEmployeeHeadingLead')"
          ></p>
        </v-card>
      </div>
    </div>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <employee-data-table></employee-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiRocketLaunch, mdiArrowRight, mdiPlay, mdiPause, mdiRefresh, mdiFullscreen, mdiMenu } from '@mdi/js';
import { ref, getCurrentInstance } from '@vue/composition-api';

import ModalWindow from '@/components/modal/ModalWindow';

import EmployeeDataTable from '@/components/table/EmployeeDataTable.vue';

export default {
  components: {
    ModalWindow,
    EmployeeDataTable,
  },
  data() {
    return {
      icons: {
        mdiRocketLaunch,
        mdiArrowRight,
        mdiPlay,
        mdiPause,
        mdiRefresh,
        mdiFullscreen,
        mdiMenu,
      },
    };
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const toggleExclusive = ref(undefined);
    const addEmployeeModal = ref(false);

    const { userData } = vm.$store.getters;
    const { activeRole } = vm.$store.getters;

    return {
      toggleExclusive,
      activeRole,
      addEmployeeModal,

      userData,
      personalData: {
        salutation: '',
        firstName: '',
        lastName: '',
        city: '',
        postalCode: '',
        streetAddress: '',
        country: '',
        cityOfBirth: '',
        countryOfBirth: '',
        dateOfBirth: '',
        emailPersonal: '',
        emailBusiness: '',
        phoneNumber: '',
      },
    };
  },
};
</script>
